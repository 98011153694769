.section {
  height: 100vh;
  position: relative;
  background-color: rgb(0, 0, 0);
  z-index: 5;
}
.section::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  z-index: 0;
  background: url('../../assets/images/section-one-stars-1.png');
  background-size: 110% 50%;
}
.active {
  transform: translateY(-100vh);
  transition: 0.8s transform ease;
}
.activeFooter {
  transform: translateY(-100vh);
  transition: 0.4s transform ease;
}
.inactive {
  transform: translateY(0);
  transition: 0.8s transform ease;
}
.blur_right {
  overflow: hidden;
  position: absolute;
  right: 0;
  z-index: 0;
  width: 20%;
  height: 50%;
  background: #923DFF;
  filter: blur(400px);
}
.blur_left {
  overflow: hidden;
  position: absolute;
  left: -8%;
  z-index: 0;
  top: -20%;
  width: 35%;
  height: 45%;
  background: #3D8AFF;
  filter: blur(400px);
}
.hr {
  position: absolute;
  width: 100%;
  height: 1px;
  margin-top: -5px;
  background: hsla(0, 0%, 100%, 0.2);
}