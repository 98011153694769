.country {
  display: inline-block;
  width: calc(100% / 11);
  cursor: pointer;
}
.img {
  height: 100vh;
  width: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}
.img_block {
  position: relative;
  z-index: 10;
  transition: 0.5s transform ease;
  will-change: transform;
}
.name {
  font-family: 'HelveticaNeueMedium', sans-serif;
  /* width: 100%; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 56.5714px;
  line-height: 1;
  letter-spacing: 0.05em;
  color: #e8e3e3;
  text-transform: uppercase;
  /* position: absolute; */
  /* transform: rotate(-90deg); */
  writing-mode: vertical-rl;
  text-orientation: mixed;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(180deg);
}
.button_content {
  position: absolute;
  width: 3vw;
  height: 3vw;
  background: url('../../../assets/images/button-content.png') no-repeat;
  background-size: contain;
  top: calc(50% - 1.5vw);
  right: 0;
}
.button_content_flip {
  position: absolute;
  width: 4vw;
  height: 4vw;
  background: url('../../../assets/images/button-content.png') no-repeat;
  background-size: contain;
  top: calc(50vh - 3vw);
  right: 0;
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
  -ms-filter: fliph;
  filter: fliph;
}
@media screen and (max-width: 930px) {
  .name { 
    font-size: 30px;
    top: 0;
    width: 100%;
    height: 100vh;
  }
}
