* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}
@font-face { 
  font-family: "Monaco";
  src: url("./assets/fonts/MonacoRegular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}
@font-face { 
  font-family: "HelveticaNeueRegular";
  src: url("./assets/fonts/HelveticaNeueRegular.otf") format("truetype");
  font-style: normal;
  font-weight: 400;
}
@font-face { 
  font-family: "HelveticaNeueMedium";
  src: url("./assets/fonts/HelveticaNeueMedium.otf") format("truetype");
  font-style: normal;
  font-weight: 500;
}
body {
  font-family: 'HelveticaNeueRegular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden; 
  background-color: #000000;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.loading-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff00;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-family: 'HelveticaNeueRegular', sans-serif;
  color: #FFFFFF;

  font-size: 34px;
  font-weight: 400;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: center;
}